import './App.css';
import HomeComponent from './HomeComponent';

function App() {
  
  return (

    <div className="App">
      <HomeComponent name='cat'/>
    </div>
  );
}

export default App;
